<template>
  <div>
    <div class="d-flex justify-start align-center">
      <div class="demo-space-x">
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <span class="d-block text-caption black--text">Sekolah</span>
        <span
          class="d-block text-subtitle-1 font-weight-bold black--text"
          style="line-height: 1.5rem !important"
        >
          {{ schoolName }}
        </span>
      </div>
    </div>
    <div class="mt-8">
      <v-row>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="6"
          cols="6"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title>
              <v-avatar color="primary">
                <v-container class="d-flex align-center">
                  <v-img
                    :src="icons.iconBook"
                  ></v-img>
                </v-container>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-sm font-weight-bold mb-2">E-Book</p>
              <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
              <p class="text-xl font-weight-black mb-0">{{ dataSchool.count_ebook }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="6"
          cols="6"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title>
              <v-avatar color="#7D2B8B">
                <v-container class="d-flex align-center">
                  <v-img
                    :src="icons.iconMusic"
                  ></v-img>
                </v-container>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
                <p class="text-sm font-weight-bold mb-2">Audio Book</p>
                <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
                <p class="text-xl font-weight-black mb-0">{{ dataSchool.count_audio }}</p>
              </v-card-text>
            </v-card>
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="6"
          cols="6"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title>
              <v-avatar color="#F7B239">
                <v-container class="d-flex align-center">
                  <v-img
                    :src="icons.iconPlay"
                  ></v-img>
                </v-container>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
                <p class="text-sm font-weight-bold mb-2">Video Book</p>
                <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
                <p class="text-xl font-weight-black mb-0">{{ dataSchool.count_video }}</p>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="6"
          cols="6"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title>
              <v-avatar color="#2197D8">
                <v-container class="d-flex align-center">
                  <v-img
                    :src="icons.iconUser"
                  ></v-img>
                </v-container>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
                <p class="text-sm font-weight-bold mb-2">Siswa</p>
                <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
                <p class="text-xl font-weight-black mb-0">{{ dataSchool.count_student }}</p>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="6"
          cols="6"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title>
              <v-avatar color="#EE3E23">
                <v-container class="d-flex align-center">
                  <v-img
                    :src="icons.iconUser"
                  ></v-img>
                </v-container>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-sm font-weight-bold mb-2">Guru</p>
              <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
              <p class="text-xl font-weight-black mb-0">{{ dataSchool.count_teacher }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="6"
          cols="6"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title>
              <v-avatar color="#D498C4">
                <v-container class="d-flex align-center">
                  <v-img
                    :src="icons.iconUser"
                  ></v-img>
                </v-container>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-sm font-weight-bold mb-2">Guru</p>
              <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
              <p class="text-xl font-weight-black mb-0">{{ dataSchool.count_employee }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="mt-4">
      <v-row>
        <v-col
          xl="5"
          lg="5"
          md="5"
          sm="12"
          cols="12"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title>
              Absensi Siswa
              <v-spacer></v-spacer>
              <v-autocomplete
                :label="dateNow"
                outlined
                :items="date"
                item-text="name"
                item-value="id"
                dense
                class="filter"
                hide-details="none"
                @change="handleFilter($event)"
              ></v-autocomplete>

            </v-card-title>

            <v-row>
              <v-col cols="12">
                <apex-chart-expense-ratio :count="count"></apex-chart-expense-ratio>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          xl="7"
          lg="7"
          md="7"
          sm="12"
          cols="12"
        >
          <v-row>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <v-card :loading="isLoadingCard">
                <div class="position-absolute pt-7 pr-5" style="right:0;">
                  <v-btn
                    icon
                    small
                    class="me-n3 mt-n2"
                  >
                    <v-icon>
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </div>
                <v-card-text class="d-flex justify-start align-center pa-4">
                  <div class="mr-6">
                    <v-avatar
                      color="#F7B239"
                      class="d-inline-block pa-3 my-4 rounded-circle"
                    >
                      <div>
                        <v-img
                          :src="icons.iconDevices"
                          width="28"
                        ></v-img>
                      </div>
                    </v-avatar>
                  </div>
                  <div>
                    <p class="text-sm font-weight-bold mb-2">Materi E-Learning</p>
                    <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
                    <p class="text-xl font-weight-bold mb-0">{{ dataSchool.count_lesson }}</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <v-card :loading="isLoadingCard">
                <div class="position-absolute pt-7 pr-5" style="right:0;">
                  <v-btn
                    icon
                    small
                    class="me-n3 mt-n2"
                  >
                    <v-icon>
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </div>
                <v-card-text class="d-flex justify-start align-center pa-4">
                  <div class="mr-6">
                    <v-card
                      color="#2197D8"
                      class="d-inline-block pa-3 my-4 rounded-circle"
                    >
                      <div>
                        <v-img
                          :src="icons.iconReceipt"
                          width="28"
                        ></v-img>
                      </div>
                    </v-card>
                  </div>
                  <div>
                    <p class="text-sm font-weight-bold mb-2">Bank Soal</p>
                    <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
                    <p class="text-xl font-weight-bold mb-0">{{ dataSchool.count_questionbank }}</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <v-card :loading="isLoadingCard">
                <div class="position-absolute pt-7 pr-5" style="right:0;">
                  <v-btn
                    icon
                    small
                    class="me-n3 mt-n2"
                  >
                    <v-icon>
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </div>
                <v-card-text class="d-flex justify-start align-center pa-4">
                  <div class="mr-6">
                    <v-card
                      color="#7D2B8B"
                      class="d-inline-block pa-3 my-4 rounded-circle"
                    >
                      <div>
                        <v-img
                          :src="icons.iconMessage"
                          width="28"
                        ></v-img>
                      </div>
                    </v-card>
                  </div>
                  <div>
                    <p class="text-sm font-weight-bold mb-2">Postingan Forum</p>
                    <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
                    <p class="text-xl font-weight-bold mb-0">{{ dataSchool.count_forum }}</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <v-card :loading="isLoadingCard">
                <div class="position-absolute pt-7 pr-5" style="right:0;">
                  <v-btn
                    icon
                    small
                    class="me-n3 mt-n2"
                  >
                    <v-icon>
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </v-btn>
                </div>
                <v-card-text class="d-flex justify-start align-center pa-4">
                  <div class="mr-6">
                    <v-card
                      color="#EE3E23"
                      class="d-inline-block pa-3 my-4 rounded-circle"
                    >
                      <div>
                        <v-img
                          :src="icons.iconDanger"
                          width="28"
                        ></v-img>
                      </div>
                    </v-card>
                  </div>
                  <div>
                    <p class="text-sm font-weight-bold mb-2">Pelanggaran</p>
                    <p class="text-xl font-weight-black mb-0" v-if="isLoadingCard">0</p>
                    <p class="text-xl font-weight-bold mb-0">{{ dataSchool.count_violation }}</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <v-card class="pa-5">
                <div class="d-flex justify-start align-center">
                  <div class="mr-6">
                    <v-card
                      color="#D498C4"
                      class="d-inline-block pa-3 my-4 rounded-circle"
                    >
                      <div>
                        <v-img
                          :src="icons.iconDocument"
                          width="28"
                        ></v-img>
                      </div>
                    </v-card>
                  </div>
                  <div class="mb-3">
                    <span class="d-block text-subtitle-2 black--text">Lihat semua data sekolah</span>
                    <router-link
                      :to="schoolData"
                      class="text-decoration-none text-subtitle-2"
                    >
                      Lihat Data
                    </router-link>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import iconSchool from '@/assets/icons/courthouse.svg'
import iconBook from '@/assets/icons/white/book.svg'
import iconDanger from '@/assets/icons/white/danger.svg'
import iconDevices from '@/assets/icons/white/devices.svg'
import iconDocument from '@/assets/icons/white/document.svg'
import iconMessage from '@/assets/icons/white/messages-2.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconUser from '@/assets/icons/white/user.svg'
import { mdiTownHall, mdiDotsVertical } from '@mdi/js'
import ApexChartExpenseRatio from './ApexChartExpenseRatio.vue'

export default {
  name: 'DashboardSchool',
  components: {
    ApexChartExpenseRatio,
  },
  data() {
    return {
      count: [0, 0, 0, 0],
      icons: {
        iconSchool,
        iconUser,
        iconDanger,
        iconBook,
        iconDevices,
        iconMessage,
        iconMusic,
        iconPlay,
        iconReceipt,
        iconDocument,
        mdiTownHall,
        mdiDotsVertical,
      },
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      dataSchool: {},
      schoolName: '',
      paramsDate: '',
      dateNow: '',
      service: 'dashboardschool',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      schoolData: '',
      isLoadingCard: true,
    }
  },
  async mounted() {
    this.schoolName = JSON.parse(localStorage.getItem('user')).user.name
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
    await this.getDataCount({ date: this.paramsDate })
    this.getSchoolData()
  },
  methods: {
    async handleFilter(id) {
      await this.getDataCount({ date: id })
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    getSchoolData() {
      this.schoolData = JSON.parse(localStorage.getItem('user')).user.details[0].data_school
    },
    async getDataCount(params) {
      await this.$services.ApiServices.list(this.service, { ...params }).then(
        ({ data }) => {
          this.dataSchool = { ...data.data }
          this.count = Object.values(this.dataSchool.absen)
        },
        err => console.error(err),
      )
      this.isLoadingCard = false
    },
  },
}
</script>

<style>
</style>
